import Link from "next/link";

const NavTwo = () => {

  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="with-megamenu has-menu-child-item position-static">
          <Link
            href="#features"
          >
            Features
          </Link>
        </li>

        <li className="with-megamenu has-menu-child-item">
          <Link
            href="#guide"
          >
            Guide
          </Link>
        </li>
        <li className="has-dropdown has-menu-child-item">
          <Link
            href="#statistics"
          >
            Statistics
          </Link>
        </li>
        <li className="with-megamenu has-menu-child-item position-static">
          <Link
            href="#subscribe"
          >
            Subscribe
          </Link>
        </li>
      </ul>
    </nav>
  );
};
export default NavTwo;
