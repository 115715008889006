import Head from "next/head";

const PageHead = ({ title }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="./favicon.ico" />
        <meta name="description" content="An automated accounting tool for investments of cryptocurrency, suitable for scenarios involving team funds that need to be invested in cryptocurrency." />
        <meta name="keywords" content="CryptoPalette, Bitcoin, Ethereum, Cryptocurrency, Investment tool, Accounting tool for cryptocurrency" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:image" content="https://cryptopalette.xyz/opengraph-image.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />

        <meta name="twitter:image" content="https://cryptopalette.xyz/twitter-image.png" />
        <meta name="twitter:image:type" content="image/png" />
        <meta name="twitter:image:width" content="500" />
        <meta name="twitter:image:height" content="500" />
      </Head>
    </>
  );
};

export default PageHead;
